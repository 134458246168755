<template>
    <user-dashboard-layout>
        <v-container fluid>
            <v-row>
                <v-col>
                    <v-card class="mx-auto px-15 py-15" flat outlined max-width="800" :loading="loading">
                        <v-card-title>
                            You're subscribing to {{ selectedPlan.name }}
                        </v-card-title>
                        <v-card-text>
                            <stripe-checkout-card @submit-card="subscribe"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import BillingApi from "../../../api/BillingApi";
    import StripeCheckoutCard from "../../../components/StripeCheckoutCard";
    import { mapMutations } from "vuex";
    import UserDashboardLayout from "../../../layouts/UserDashboardLayout";

    export default {
        name: 'Checkout',
        components: {
            StripeCheckoutCard, UserDashboardLayout
        },
        data: function () {
            return {
                addPaymentStatusError: '',
                selectedPlan: {},
                loading: false,
            }
        },
        methods: {
            subscribe(method){
                this.loading = true;

                // try {
                //     await BillingApi.updateSubscription(method, this.selectedPlan.stripe_id)
                //     await this.$store.dispatch('getAuthUser')
                //     this.showSnackBar({ color: 'success', timeout: 3000, text: 'Subscription confirmed' })
                // } catch (e) {
                //     this.showSnackBar({ color: 'error', timeout: 3000, text: e.response?.data?.message || e })
                // } finally {
                //     this.loading = false
                // }

                console.log(this.selectedPlan.stripe_id);

                BillingApi.updateSubscription(method, this.selectedPlan.stripe_id)
                    .then(() => {
                        this.showSnackBar({ color: 'success', timeout: 3000, text: 'Subscription confirmed' })
                    })
                    .catch((e) => {
                        this.showSnackBar({ color: 'error', timeout: 3000, text: e.response?.data?.message || e })
                    })
                    .finally(() => this.loading = false)
            },
            ...mapMutations(['showSnackBar'])
        },
        mounted() {
            if (this.$route.params.plan) {
                this.selectedPlan = this.$route.params.plan;
            } else {
                this.$router.push({ name: 'dashboard.billing.select-plan' })
            }
        },
    }
</script>

<style scoped>

</style>
